import { Link } from "gatsby";
import React from "react";
import { ArrowUpRight } from '@styled-icons/bootstrap';
import { WithFadeUp } from "../../../hoc/withFadeup";

const HeroBadge = () => {
   return null;
   // button todo
   return (
      <button className='text-[.75rem] py-[2px] leading-5 border border-solid border-gray-200 px-4 font-medium rounded-xl bg-gray-100/90 mb-2'>
         The future of AI in Unizo
      </button>
   )
}

export const Title = ({ children = null }: { children: any }) => {
   return (
      <h2 className='section_title text-center !text-[3rem] lg:!text-[4rem]'>
         {children}
      </h2>
   )
}

type HeroBannerTypes = {
   title?: string
   title2?: string
   description?: string
   minimal?: boolean
}

export const HeroBanner = (props: HeroBannerTypes) => {
   const {
      title = <span>Turnkey Integrations for <span className="primary-text-gradient">Cybersecurity</span> and <span className="primary-text-gradient">DevOps</span> </span>,
      title2 = null,
      description = `A single platform for all integration needs for SaaS providers to launch native integrations across categories within days. Unizo manages your integrations, helping you focus on growing your business.`,
      minimal
   } = props;
   return (
      <div className='banner_spacing_md'>
         <section className="relative layout noise-bg pb-5 no-underline-links px-4 lg:py-0">
            <div className="flex compact w-full ml-auto flex-col gap-5 items-center justify-between py-14">
               <HeroBadge />
               {title && (
                  <Title >{title} </Title>
               )}
               {title2 && (
                  <Title>{title2}</Title>
               )}
               {description && (
                  <p className="text-xl mb-0 text-gray-700 font-small text-center">
                     {description}
                  </p>
               )}
               {
                  !minimal && (
                     <div className='flex items-center flex-col gap-2'>
                        <div className='flex flex-col lg:flex-row items-center gap-2'>
                           <Link to="/lets-chat" className='btn  btn-lg btn-primary btn-anim'>
                              Try Demo
                           </Link>
                           <Link className='btn btn-lg' to="https://docs.unizo.io">
                              Explore Docs <ArrowUpRight className="h-4 w-4" />
                           </Link>
                        </div>
                     </div>
                  )
               }
            </div>
            {!minimal && (
               <img alt="portal_main_img" className="scale-[.9] lg:scale-[1] portal_image ml-auto w-full p-2  m-auto " src="/images/portal_main.svg" />
            )}
         </section>
      </div>
   )
}
